<template>
     <div class="list-group">
        <router-link to="/admin" class="list-group-item"><i class="fa fa-home"></i> <span>Inicio</span></router-link>
        <router-link to="/admin/users" class="list-group-item"><i class="fa fa-book"></i> <span>Usuarios</span></router-link>
        <router-link to="/admin/coupons" class="list-group-item" :class="{'active':selectecMenu=='coupons'}"><i class="fa fa-credit-card"></i> <span>Cupones</span></router-link>
        <router-link to="/admin/orders" class="list-group-item" :class="{'active':selectecMenu=='orders'}"><i class="fa fa-tag"></i> <span>Ordenes</span></router-link>

        <router-link to="/admin/blog" class="list-group-item"><i class="fa fa-edit"></i> <span>blog</span></router-link>
        <router-link to="/admin/me" class="list-group-item"><i class="fa fa-key"></i> <span>Mis datos</span></router-link>
    </div>
</template>
<script>
export default {
    name:"menuAdmin",
    props: {
        selectecMenu:{
            default: ""
        }
    }
}
</script>

